import { Box, Button, TextField, Select, MenuItem, Typography, useTheme  } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { tokens } from "../theme";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "./Header";  
import axios from 'axios';
import { useEffect } from "react";
import { useState } from "react";
import InputLabel from "@mui/material/InputLabel"
import { toast } from 'react-toastify';



const Form = () => {
  const inputStyle = { WebkitBoxShadow: "0 0 0 1000px #191a1a  inset" };
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const reader = new FileReader();
  const [citys, setCitys] = useState([]);
  const [categorys, setCategorys] = useState([]);
  const [isPauseBookingAllowed, setIsPauseBookingAllowed] = useState(false)
  // const [selectedFile, setSelectedFile] = useState(null);
  // const [base64Image, setBase64Image] = useState('');

  const [eventType, setEventType] = useState([{ type: '', price: '' }]);

  const handleEventType = () => {
    setEventType([...eventType, { type: '', price: '' }]);
  };

  const [artists, setArtists] = useState([{ artist: '', artist_url: null }]);
  const [images, setImages] = useState(['']);
  const [layoutImage, setLayoutImage] = useState('');

  const handleAddArtist = () => {
    setArtists([...artists, { artist: '', artist_url: null }]);
  };

  const handleAddImage = () => {
    setImages([...images, '']);
  };

  const handleSuccess = () => {
    // Display a success toast
    toast.success('Event Added Successfully', {
      position: 'top-right',
      autoClose: 1000,
    });
  
    // Reload the page after 3000 milliseconds (3 seconds)
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const handleLayoutImageChange = async (event) => {
    const { files } = event.target;

      try {
        var currentImage = files[0];
        const formData = new FormData();
        formData.append('file', currentImage);

        const response = await axios.post(
          'https://api-dev-ticket.wecodelife.com/v1/data/dashboard/upload-file/',
          formData,
          {
            headers: {
              'Token': `${getAuthToken()}`,
              'Authorization': `Token ${getAuthToken()}`,
              'Content-Type': 'multipart/form-data',
            },
          }
        );
      
        // console.log("return image ", response.data.data.download_url);
        setLayoutImage(response.data.data.download_url)
      
        // Handle successful response here
      } catch (error) {
        if (error.response) {
          // The request was made, but the server responded with a non-2xx status code
          console.error("Server responded with an error status:", error.response.status);
          console.error("Error response data:", error.response.data);
        } else if (error.request) {
          // The request was made, but no response was received
          console.error("No response received from the server");
        } else {
          // Something else went wrong
          console.error("Error:", error.message);
        }
      }
  
  };


  useEffect(() => {
      axios.get('https://api-dev-ticket.wecodelife.com/v1/data/city-list/')
      .then((response) => {
        setCitys(response.data.data);
      })
      .catch((error) => {
        console.error('Error fetching Cities:', error);
      });

      axios.get('https://api-dev-ticket.wecodelife.com/v1/data/categories-list/')
      .then((response) => {
        // console.log(response.data.data);
        setCategorys(response.data.data);
      })
      .catch((error) => {
        console.error('Error fetching Categories:', error);
      });


  }, []);


  const getAuthToken = () => {
    return JSON.parse(localStorage.getItem('authToken'));
  };

  const getLoginId = () => {
    return JSON.parse(localStorage.getItem('loginId'));
  };


  const handleInputChange = (index, event, fieldName) => {
    const { value } = event.target;
    const newEventType = [...eventType];
    newEventType[index][fieldName] = value;
    setEventType(newEventType);
  };

  const handleArtistInputChange = async (index, event) => {
    const { name, value, files } = event.target;
    const newArtists = [...artists];
    
    if (name === 'name') {
      newArtists[index].artist = value;
    } else if (name === 'image') {
      try {
        var currentImage = files[0];
        const formData = new FormData();
        formData.append('file', currentImage);

        const response = await axios.post(
          'https://api-dev-ticket.wecodelife.com/v1/data/dashboard/upload-file/',
          formData,
          {
            headers: {
              'Token': `${getAuthToken()}`,
              'Authorization': `Token ${getAuthToken()}`,
              'Content-Type': 'multipart/form-data',
            },
          }
        );
      
        // console.log("return image ", response.data.data.download_url);
        newArtists[index].artist_url = response.data.data.download_url;
      
        // Handle successful response here
      } catch (error) {
        if (error.response) {
          // The request was made, but the server responded with a non-2xx status code
          console.error("Server responded with an error status:", error.response.status);
          console.error("Error response data:", error.response.data);
        } else if (error.request) {
          // The request was made, but no response was received
          console.error("No response received from the server");
        } else {
          // Something else went wrong
          console.error("Error:", error.message);
        }
      }
      

    
    }

    setArtists(newArtists);
  };
 
 
  const handleImageChange = async (index, event) => {
    const { name, files } = event.target;
    const newImages = [...images];
    
     if (name === 'image') {
      try {
        var currentImage = files[0];
        const formData = new FormData();
        formData.append('file', currentImage);

        const response = await axios.post(
          'https://api-dev-ticket.wecodelife.com/v1/data/dashboard/upload-file/',
          formData,
          {
            headers: {
              'Token': `${getAuthToken()}`,
              'Authorization': `Token ${getAuthToken()}`,
              'Content-Type': 'multipart/form-data',
            },
          }
        );
      
        // console.log("return image ", response.data.data.download_url);
        newImages[index] = response.data.data.download_url;
      
        // Handle successful response here
      } catch (error) {
        if (error.response) {
          // The request was made, but the server responded with a non-2xx status code
          console.error("Server responded with an error status:", error.response.status);
          console.error("Error response data:", error.response.data);
        } else if (error.request) {
          // The request was made, but no response was received
          console.error("No response received from the server");
        } else {
          // Something else went wrong
          console.error("Error:", error.message);
        }
      }
    
    }

    setImages(newImages);
  };
  

  const isNonMobile = useMediaQuery("(min-width:600px)");

  const handleFormSubmit = async (values, { resetForm }) => {
    try {
      const clonedValues = JSON.parse(JSON.stringify(values));
      const termsArray = values.terms.split('\n');
      // console.log(termsArray);
      clonedValues.more_fields =
        {
          'age': values.age,
          'terms': termsArray,
          'artists': artists,
          'language': values.language,
        }

      clonedValues.ticket_types = {"pricing":eventType};
      clonedValues.images = images;
      clonedValues.image = images[0];
      clonedValues.layout_image = layoutImage;
      clonedValues.created_by = getLoginId()
      clonedValues.date = values.fromdate;
      clonedValues.date_string = values.todate;
      clonedValues.lat = "0.0000000001";
      clonedValues.lon = "0.0000000001";
      clonedValues.pause_booking = isPauseBookingAllowed;
 
      console.log('Submitted Data:', clonedValues);
      


      // Make a POST request to the API endpoint to create an event
      const response = await axios.post(
        'https://api-dev-ticket.wecodelife.com/v1/data/dashboard/create-event/',clonedValues,
        {
          headers: {
            'Token': `${getAuthToken()}`,
            'Authorization': `Token ${getAuthToken()}`,
            'Content-Type': 'application/json',
          },
        }
      );

      // Check if the response status is successful (e.g., HTTP 200)
      if (response.status === 200 || response.status === 201) {
        console.log('Response:', response.data);
        handleSuccess()
      } else {
        // Handle unexpected response status codes
        console.error('Unexpected response:', response.status);
        alert('An unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle network or request errors
      console.error('Error:', error);

      // Check if the error is due to unauthorized access (401)
      if (error.response && error.response.status === 401) {
        alert('Unauthorized access. Please log in again.');
        // You might want to redirect the user to the login page here
        window.location='/';
      } else {
        // Handle other errors
        alert('An error occurred. Please try again later.');
      }
    }
    resetForm();
  };

  return (
    <Box m="20px" className="event_form main-dashboard">
      <Header title="CREATE EVENT" subtitle="Create a New Event" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          
        }) => (
          <form onSubmit={handleFormSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Event Title"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.title}
                name="title"
                error={!!touched.title && !!errors.title}
                helperText={touched.title && errors.title}
                sx={{ gridColumn: "span 4" }}
                InputLabelProps={{
                  style: {
                    color: colors.color="#FFFFFF" // Change the color to your desired color
                  },
                }}
              />
              <TextField
                
                fullWidth
                multiline  // This enables multiline input
                rows={9}    // You can adjust the number of rows as needed
                variant="filled"
                type="text"
                label="Event Description"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
                name="description"
                error={!!touched.description && !!errors.description}
                helperText={touched.description && errors.description}
                sx={{ gridColumn: "span 4" }}
                InputLabelProps={{
                  style: {
                    color: colors.color="#FFFFFF" // Change the color to your desired color
                  },
                }}
              />
        
              {/* <TextField
                fullWidth
                variant="filled"
                type="file"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.image}
                name="image"
                 error={!!touched.image && !!errors.image}
                 helperText={touched.image && errors.image}
                sx={{ gridColumn: "span 2" }}
              />   */}
{/* 
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="Event Price"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.price}
                name="price"
                error={!!touched.price && !!errors.price}
                helperText={touched.price && errors.price}
                sx={{ gridColumn: "span 2" }}
              /> */}

                <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Venue"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.place}
                name="place"
                 inputProps={{ style: inputStyle }}
                error={!!touched.place && !!errors.place}
                helperText={touched.place && errors.place}
                sx={{ gridColumn: "span 2" }}
                InputLabelProps={{
                  style: {
                    color: colors.color="#FFFFFF" // Change the color to your desired color
                  },
                }}
              />

            <TextField
                fullWidth
                variant="filled"
                type="time"
                label="time"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.time}
                name="time"
                error={!!touched.time && !!errors.time}
                helperText={touched.time && errors.time}
                sx={{
                  gridColumn: "span 2",
                  "& input[type='time']::-webkit-calendar-picker-indicator": {
                    filter: "invert(1)", // Change color as needed
                  },
                  "& label": {
                    color: colors.color="#FFFFFF", // Change label color
                    transform: "translate(0, 1.5px)", // Move the label up slightly
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: colors.color="#FFFFFF" // Change the color to your desired color
                  },
                }}
              />



              <Box sx={{ gridColumn: "span 2",position:"relative" }}>
              <Typography sx={{ gridColumn: "span 4",position:"absolute",top:"-15px" }}>
                Category
              </Typography>
              
                <Select
              labelId="category-label"
              id="category"
              value={values.category}
              name="category"
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!touched.category && !!errors.category}
              label="category"
              sx={{ width:"100%",marginTop:"35px" }}
            >
              <MenuItem value="">Select an option</MenuItem>
              {categorys.map((category,i)=>(
              <MenuItem key={i} value={category.id}>{category.name}</MenuItem>
              ))}
            </Select>
            </Box>

            <Box sx={{ gridColumn: "span 2",position:"relative"  }}>
              <Typography sx={{ gridColumn: "span 4",position:"absolute",top:"-15px" }}>
                City
              </Typography>
              
                <Select
              labelId="city-label"
              id="city"
              value={values.city}
              name="city"
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!touched.city && !!errors.city}
              label="city"
              sx={{ width:"100%",marginTop:"35px" }}
            >
              <MenuItem value="">Select an option</MenuItem>
              {citys.map((city,i)=>(
              <MenuItem key={i} value={city.id}>{city.name}</MenuItem>
              ))}
            </Select>
            </Box>

           
               {/* <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Ticket Types"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.ticket_types}
                name="ticket_types"
                error={!!touched.ticket_types && !!errors.ticket_types}
                helperText={touched.ticket_types && errors.ticket_types}
                sx={{ gridColumn: "span 2" }}
              /> */}

              <TextField
                fullWidth
                variant="filled"
                type="date"
                label="From Date"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.fromdate}
                name="fromdate"
                error={!!touched.fromdate && !!errors.fromdate}
                helperText={touched.fromdate && errors.fromdate}
                sx={{
                  gridColumn: "span 2",
                  "& input[type='date']::-webkit-calendar-picker-indicator": {
                    filter: "invert(1)", // Change color as needed
                  },
                  "& label": {
                    color: colors.color="#FFFFFF", // Change label color
                    transform: "translate(0, 1.5px)", // Move the label up slightly
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: colors.color="#FFFFFF", // Change the color to your desired color
                    transform: 'translate(0, 1.5px)', // Move the label up slightly
                  },
                }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="date"
                label="To Date"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.todate}
                name="todate"
                error={!!touched.todate && !!errors.todate}
                helperText={touched.todate && errors.todate}
                sx={{
                  gridColumn: "span 2",
                  "& input[type='date']::-webkit-calendar-picker-indicator": {
                    filter: "invert(1)", // Change color as needed
                  },
                  "& label": {
                    color: colors.color="#FFFFFF", // Change label color
                    transform: "translate(0, 1.5px)", // Move the label up slightly
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: colors.color="#FFFFFF", // Change the color to your desired color
                    transform: 'translate(0, 1.5px)', // Move the label up slightly
                  },
                }}
              />

   
             
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Location"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.address}
                inputProps={{ style: inputStyle }}
                name="address"
                 error={!!touched.address && !!errors.address}
                 helperText={touched.address && errors.address}
                sx={{ gridColumn: "span 2" }}
                InputLabelProps={{
                  style: {
                    color: colors.color="#FFFFFF" // Change the color to your desired color
                  },
                }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Age Limit"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.age}
                inputProps={{ style: inputStyle }}
                name="age"
                 error={!!touched.age && !!errors.age}
                 helperText={touched.age && errors.age}
                sx={{ gridColumn: "span 2" }}
                InputLabelProps={{
                  style: {
                    color: colors.color="#FFFFFF" // Change the color to your desired color
                  },
                }}
              />
               <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Booking Fee (%)"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.handling_fee}
                inputProps={{ style: inputStyle }}
                name="handling_fee"
                 error={!!touched.handling_fee && !!errors.handling_fee}
                 helperText={touched.handling_fee && errors.handling_fee}
                sx={{ gridColumn: "span 2" }}
                InputLabelProps={{
                  style: {
                    color: colors.color="#FFFFFF" // Change the color to your desired color
                  },
                }}
              />
               <TextField
                fullWidth
                variant="filled"
                type="number"
                label="Duration (Hr)"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.duration}
                inputProps={{ style: inputStyle }}
                name="duration"
                 error={!!touched.duration && !!errors.duration}
                 helperText={touched.duration && errors.duration}
                sx={{ gridColumn: "span 2" }}
                InputLabelProps={{
                  style: {
                    color: colors.color="#FFFFFF" // Change the color to your desired color
                  },
                }}
              />
              <TextField
                fullWidth
                multiline  // This enables multiline input
                rows={8}    // You can adjust the number of rows as needed
                variant="filled"
                label="Terms and conditions"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.terms}
                name="terms"
                error={!!touched.terms && !!errors.terms}
                helperText={touched.terms && errors.terms}
                sx={{ gridColumn: "span 4" }}
                InputLabelProps={{
                  style: {
                    color: colors.color="#FFFFFF" // Change the color to your desired color
                  },
                }}
              />

              {/* <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Artists"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.artist}
                name="artist"
                 error={!!touched.artist && !!errors.artist}
                 helperText={touched.artist && errors.artist}
                sx={{ gridColumn: "span 2" }}
              /> */}

               <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Hosted By"
                onBlur={handleBlur}
                onChange={handleChange}
                inputProps={{ style: inputStyle }}
                value={values.host}
                name="host"
                 error={!!touched.host && !!errors.host}
                 helperText={touched.host && errors.host}
                sx={{ gridColumn: "span 2" }}
                InputLabelProps={{
                  style: {
                    color: colors.color="#FFFFFF" // Change the color to your desired color
                  },
                }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Language"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.language}
                inputProps={{ style: inputStyle }}
                name="language"
                 error={!!touched.language && !!errors.language}
                 helperText={touched.language && errors.language}
                sx={{ gridColumn: "span 2" }}
                InputLabelProps={{
                  style: {
                    color: colors.color="#FFFFFF" // Change the color to your desired color
                  },
                }}
              />

              <Box sx={{position:"relative", width:"100vw"}}>
              <Typography sx={{ gridColumn: "span 4", position:"absolute", top:"-24px"}}>
                Layout Image
              </Typography>
              <TextField
                  fullWidth
                  variant="filled"
                  type="file"
                  onBlur={handleBlur}
                  name="image" 
                  onChange={(e) => handleLayoutImageChange(e)}
                  sx={{ gridColumn: "span 2" }}
                />
              </Box>

              {/* <Typography sx={{ gridColumn: "span 4" }}>
                Select Ticket Checker
              </Typography> */}
              
        
            </Box>

            {/* Multiple Ticket type adding */}

            <Box display="flex" justifyContent="start" mt="20px" gap="20px" backgroundColor={colors.grey[950]}  p="10px">
            <Typography width="50%" sx={{ color: 'red',fontSize: '16px' }}>
                Ticket Type
              </Typography>
              <Box display="flex" justifyContent="space-between" width="50%">
            <Typography sx={{ color: 'red',fontSize: '17px' }} >
                Price
              </Typography>
              <button className="add_btn" type="button"  width="30%" onClick={handleEventType}>Add More Ticket Types +</button>
              </Box>
            </Box>
            {eventType.map((event, index) => (
  <Box key={index} display="flex" justifyContent="center" mt="20px" gap="20px">
    <TextField
      fullWidth
      variant="filled"
      type="text"
      inputProps={{ style: inputStyle }}
      label="Ticket Type"
      onBlur={handleBlur}
      name={`type_${index}`} // Use a unique name for event type
      value={event.type}
      onChange={(e) => handleInputChange(index, e, 'type')} // Pass the field name as a parameter
      sx={{ gridColumn: "span 2" }}
      InputLabelProps={{
        style: {
          color: colors.color="#FFFFFF" // Change the color to your desired color
        },
      }}
    />
    <TextField
      fullWidth
      variant="filled"
      type="number"
      label="Ticket Price"
      onBlur={handleBlur}
      name={`price_${index}`} // Use a unique name for event price
      value={event.price}
      onChange={(e) => handleInputChange(index, e, 'price')} // Pass the field name as a parameter
      sx={{ gridColumn: "span 2" }}
      InputLabelProps={{
        style: {
          color: colors.color="#FFFFFF" // Change the color to your desired color
        },
      }}
    />
  </Box>
))}


                {/* Multiple Artist Adding  */}

                <Box display="flex" justifyContent="start" mt="20px" gap="20px" backgroundColor={colors.greenAccent[950]}  p="10px">
            <Typography width="50%"sx={{ color: 'red',fontSize: '17px' }}>
                Artist
              </Typography>
              <Box display="flex" justifyContent="space-between" width="50%">
            <Typography sx={{ color: 'red',fontSize: '16px' }} >
               Artist Image
              </Typography>
              <button className="add_btn" type="button"  width="30%" onClick={handleAddArtist}>Add More Artist +</button>
              </Box>
            </Box>
            {artists.map((artist, index) => (
              <Box key={index} display="flex" justifyContent="center" mt="20px" gap="20px">
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Artist Name"
                  inputProps={{ style: inputStyle }}
                  onBlur={handleBlur}
                  name="name"
                  value={artist.artist} 
                  onChange={(e) => handleArtistInputChange(index, e)} 
                  sx={{ gridColumn: "span 2" }}
                  InputLabelProps={{
                    style: {
                      color: colors.color="#FFFFFF" // Change the color to your desired color
                    },
                  }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="file"
                  onBlur={handleBlur}
                  name="image" 
                  onChange={(e) => handleArtistInputChange(index, e)} 
                  sx={{ gridColumn: "span 2" }}
                />
              </Box>
            ))}

            {/* Event Images  */}

            <Box display="flex" justifyContent="start" mt="20px" gap="20px" backgroundColor={colors.grey[950]}  p="10px">
            <Typography width="50%">
                Event Images
              </Typography>
              <Box display="flex" justifyContent="end" width="50%">
              <button className="add_btn" type="button"  width="30%" onClick={handleAddImage}>Add More Image +</button>
              </Box>
            </Box>
            {images.map((image, index) => (
              <Box key={index} display="flex" justifyContent="center" mt="20px" gap="20px">
                <TextField
                  fullWidth
                  variant="filled"
                  type="file"
                  onBlur={handleBlur}
                  name="image" 
                  onChange={(e) => handleImageChange(index, e)} 
                  sx={{ gridColumn: "span 2" }}
                />
              </Box>
            ))}

            <br />
            <br />

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Number of Tickets Available"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.available_tickets}
                inputProps={{ style: inputStyle }}
                name="available_tickets"
                 error={!!touched.available_tickets && !!errors.available_tickets}
                 helperText={touched.available_tickets && errors.available_tickets}
                sx={{ gridColumn: "span 2" }}
                InputLabelProps={{
                  style: {
                    color: colors.color="#FFFFFF" // Change the color to your desired color
                  },
                }}
              />

              <br />
              <br />

                <span style={{display:"flex",minWidth:"300px",alignItems:"baseline"}}>
                <input style={{cursor:"pointer"}} type="checkbox" id="alowAdd"  name="disc" checked={isPauseBookingAllowed} onChange={()=>{setIsPauseBookingAllowed(!isPauseBookingAllowed)}} /> &nbsp;<label style={{cursor:"pointer"}} htmlFor="alowAdd">Allow Pause Booking</label>
               </span> 


            <Box display="flex" justifyContent="end" mt="20px" >
              <Button type="submit" color="secondary" variant="contained" onClick={handleSubmit}>
                Add Event
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  title: yup.string().required("required"),
  description: yup.string().required("required"),
  fromdate: yup.string().required("required"),
  time: yup.string().required("required"),
  todate: yup.string().required("required"),
  place: yup.string().required("required"),
  city: yup.string().required("required"),
  address: yup.string().required("required"),
  host: yup.string().required("required"),
  age:yup.string().required("required"),
  terms: yup.string().required("required"),
  handling_fee:yup.string().required("required"),
  // artist:yup.string().required("required"),
  // image: yup.string().required("required"),
  // price: yup.string().required("required"),
  category: yup.string().required("required"),
  available_tickets: yup.string().required("required"),
  // language: yup.string().required("required"),
  // name: yup.string().required("required"),  
  
});
const initialValues = {
  title: "",
  description: "",
  place: "",
  address:"",
  map_location:"",
  ticket_types:"",
  date:"",
  date_string: "",
  time:"",
  duration:"",
  image:"",
  images:"",
  layout_image:"",
  lat:"",
  lon:"",
  more_fields:"",
  city:"",
  handling_fee:"",
  category:"",
  available_tickets:"",
};

export default Form;