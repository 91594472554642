import React from 'react'
import'./css/Login.css';
import { toast } from 'react-toastify';
import { useState } from 'react';
import axios from 'axios';



const LoginPage = () => {



    const [email,setEmail] = useState("")
    const [password,setPassword] = useState("")

    const handleSuccess = () => {
        // Display a success toast
        toast.success('Login Successfully', {
          position: 'top-right',
          autoClose: 1000,
        });

        setTimeout(()=>{
            window.location='/home';
            
        },1000)
        
      };

    const handlError = () => {
        // Display a success toast
        toast.error('Invalid Details', {
          position: 'top-right',
          autoClose: 1000,
        });
        
      };
   
    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = {
            'email':email,
            'password':password
        }
        
    try {
        const response = await axios.post('https://api-dev-ticket.wecodelife.com/v1/data/auth/admin/login/', data);
        console.log(response.data);
        const token = await response.data.data.token
        const status = await response.data.message
        const loginId = await response.data.data.user_data.id
        console.log(token);

        // Store the token in localStorage
        localStorage.setItem('authToken', JSON.stringify(token));
        localStorage.setItem('loginId', JSON.stringify(loginId));

        if(status === 'User login successfully'){
            handleSuccess();
        }else{
            // alert("Invalid Email or Password")
            handlError()
        }

      } catch (error) {
        // alert("Invalid Email or Password")
        handlError()
        // console.error('Login error:', error.response.data);
        // console.error('Status code:', error.response.status);
      }

    }


  return (
<div className="login-container">
    <div className="login-wrapper">
        <p className="signin-message">Sign in to your account to Continue</p>
        <form onSubmit={handleSubmit} noValidate>
            <div className="email-container">
                <label htmlFor="email">Email or Username</label>
                <input
                    type="text"
                    className="email-input"
                    placeholder="Enter your email or username"
                    noValidate
                    onChange={(e) => setEmail(e.target.value)}
                />
            </div>
            <div className="password-container">
                <label htmlFor="password">Password</label>
                <input
                    type="password"
                    className="password-input"
                    placeholder="Enter your password"
                    noValidate
                    onChange={(e) => setPassword(e.target.value)}
                />
            </div>
            {/* Remember me checkbox */}
            {/* <div className="remember-me">
                <input type="checkbox" className="remember-checkbox" />
                Remember me
            </div> */}
            <div className="sign">
                <button
                    className="signin-button"
                    type="Sign"
                    onClick={handleSubmit}
                >
                    Sign in
                </button>
            </div>
        </form>
        <div className="modal-background" id="mymodal">
            {/* Modal Content */}
            {/* <div className="modal-content">
                <div className="head">
                    <div className="modal-close">+</div>
                    <h1 id="one"></h1>
                    <h1 id="two"></h1>
                </div>
                <div className="paradiv"></div>
            </div> */}
        </div>
    </div>
</div>
    
  )
}

export default LoginPage
