import { Box, Button, TextField } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "./Header";
import axios from 'axios';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { useState } from "react";



const CheckerAdd = () => {

  const [isTicketAllowed, setIsTicketAllowed] = useState(false)
  const inputStyle = { WebkitBoxShadow: "0 0 0 1000px #191a1a  inset" };
  const theme = useTheme();
  const colors = theme.palette; // Access the colors from the theme
  let { id } = useParams();

  const isNonMobile = useMediaQuery("(min-width:600px)");
  
  const getAuthToken = () => {
    return JSON.parse(localStorage.getItem('authToken'));
  };

  const handleSuccess = () => {
    // Display a success toast
    toast.success('Checker Added Successfully', {
      position: 'top-right',
      autoClose: 1500,
    });
    
  };


  const handleFormSubmit = async (values,{ resetForm }) => {
    try {
    const clonedValues = JSON.parse(JSON.stringify(values));
    clonedValues.name = clonedValues.firstName;
    delete clonedValues.firstName;
    clonedValues.token = getAuthToken();
    clonedValues.event = id;
    clonedValues.is_allowed_to_add_ticket = isTicketAllowed;
    // console.log(clonedValues);
    console.log({clonedValues});
    const response = await axios.post('https://api-dev-ticket.wecodelife.com/v1/data/dashboard/add-checker/', clonedValues, {
        headers: {
          'Token': `${getAuthToken()}`,
          'Authorization': `Token ${getAuthToken()}`,
          'Content-Type': 'application/json',
        },
      });
  
      // Check the response status code for success (e.g., HTTP 200)
      if (response.status === 200 || response.status === 201) {
        console.log('Response:', response.data);
        handleSuccess()
      } else {
        // Handle unexpected response status codes
        console.error('Unexpected response:', response.status);
        alert('An unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle network or request errors
      console.error('Error:', error);
  
      // Check if the error is due to unauthorized access (401)
      if (error.response && error.response.status === 401) {
        alert('Unauthorized access. Please log in again.');
        // You might want to redirect the user to the login page here
        window.location='/';
      } else {
        // Handle other errors
        alert('An error occurred. Please try again later.');
      }
    }
    resetForm();
    setIsTicketAllowed(false)
  
  };


  return (
    <Box m="20px">
      <Header title="CREATE CHECKER" subtitle="Create a New Ticket checker Profile" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.firstName}
                name="firstName"
                inputProps={{ style: inputStyle }}
                error={!!touched.firstName && !!errors.firstName}
                helperText={touched.firstName && errors.firstName}
                sx={{ gridColumn: "span 4" }}
                InputLabelProps={{
                  style: {
                    color: colors.color="#FFFFFF" // Change the color to your desired color
                  },
                }}
              />
              {/* <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Last Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.lastName}
                name="lastName"
                error={!!touched.lastName && !!errors.lastName}
                helperText={touched.lastName && errors.lastName}
                sx={{ gridColumn: "span 2" }}
              /> */}
            
              {/* <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Contact Number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.contact}
                name="contact"
                error={!!touched.contact && !!errors.contact}
                helperText={touched.contact && errors.contact}
                sx={{ gridColumn: "span 4" }}
              /> */}
              {/* <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Address"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.address1}
                name="address1"
                error={!!touched.address1 && !!errors.address1}
                helperText={touched.address1 && errors.address1}
                sx={{ gridColumn: "span 4" }}
              /> */}
                <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                name="email"
                inputProps={{ style: inputStyle }}
                error={!!touched.email && !!errors.email}
                helperText={touched.email && errors.email}
                sx={{ gridColumn: "span 4" }}
                InputLabelProps={{
                  style: {
                    color: colors.color="#FFFFFF" // Change the color to your desired color
                  },
                }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="password"
                label="Password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
                name="password"
                inputProps={{ style: inputStyle }}
                error={!!touched.password && !!errors.password}
                helperText={touched.password && errors.password}
                sx={{ gridColumn: "span 4" }}
                InputLabelProps={{
                  style: {
                    color: colors.color="#FFFFFF" // Change the color to your desired color
                  },
                }}
              />

              <span style={{display:"flex",minWidth:"300px",alignItems:"baseline"}}>
              <input style={{cursor:"pointer"}} type="checkbox" id="alowAdd"  name="disc" checked={isTicketAllowed} onChange={()=>{setIsTicketAllowed(!isTicketAllowed)}} /> &nbsp;<label style={{cursor:"pointer"}} htmlFor="alowAdd">Allowed to add Tickets</label>
               </span> 
            
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained" onClick={handleSubmit}>
                Assign checker
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  firstName: yup.string().required("required"),
  // email: yup.string().email("invalid email").required("required"),
  password: yup.string().required("required"),
});

const initialValues = {
  firstName: "",
  email: "",
  password:"",
};

export default CheckerAdd;
