import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import EventCard from "../../components/EventCard";
import { PartyMode } from "@mui/icons-material";
import { useEffect, useState } from "react";
import axios from 'axios';
import CouponCard from "../../components/CouponCard";
import { toast } from 'react-toastify';

const ViewCoupons = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [events, setEvents] = useState([]);

  const [coupons, setCoupons] = useState([]);
  const [response, setResponse] = useState(false);

  
  const getAuthToken = () => {
    return JSON.parse(localStorage.getItem('authToken'));
  };

  useEffect(() => {
    // Replace 'your-api-endpoint' with the actual API endpoint.
    axios.get(`https://api-dev-ticket.wecodelife.com/v1/data/dashboard/coupons-list/`,{
      headers: {
        'Token': `${getAuthToken()}`,
        'Authorization': `Token ${getAuthToken()}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        console.log(response.data);
        const activeCoupons = response.data.data.filter((coupon) => coupon.is_active == true)
        setCoupons(activeCoupons);
        setResponse(true)
      })
      .catch((error) => {
        console.error('Error fetching Coupons:', error);
      });
  }, []);


  useEffect(() => {
    // Replace 'your-api-endpoint' with the actual API endpoint.
    axios.get('https://api-dev-ticket.wecodelife.com/v1/data/events-list/')
      .then((response) => {
        console.log(response.data.data.results);
        setEvents(response.data.data.results);
      })
      .catch((error) => {
        console.error('Error fetching Events:', error);
      });
  }, []);


  const handleSuccess = () => {
    // Display a success toast
    toast.success('Coupon Deleted Successfully', {
      position: 'top-right',
      autoClose: 2000,
    });
    
  };

  const deleteCoupon = (id) => {
    const isConfirmed = window.confirm('Are you sure you want to delete this Coupon?');
    if (isConfirmed) {
      axios.delete(`https://api-dev-ticket.wecodelife.com/v1/data/dashboard/coupon-details/${id}/`,{
        headers: {
          'Token': `${getAuthToken()}`,
          'Authorization': `Token ${getAuthToken()}`,
          'Content-Type': 'application/json',
        },
      })
    .then(response => {
      setCoupons((prevEvents) => prevEvents.filter((coupon) => coupon.id !== id));
      handleSuccess()
      console.log('Coupon deleted successfully',response);
    })
    .catch(error => {
      console.error('Error deleting Coupon', error);
    });
  }
  }



  return (
    <Box m="20px" className="main-dashboard">
      {/* HEADER */}

      {
       !response ? (
          <div className="custom_loader">Loading data...</div>
        ) 
      :

      (
        <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="DASHBOARD" subtitle="Available Coupons" />
      </Box>

          
          <div style={{ display: 'flex', flexDirection: 'column',gap:'10px',maxWidth:"100%",padding:"5px 10px" }}>
          {coupons.length == 0 ?
          (
            <div className="no_data_container_left">No Data!</div>
          ) :
          
          coupons.map((coupon)=>(
            <CouponCard
            key={coupon.id}
            code={coupon.code}
            event={coupon.event_name}
            percentage={coupon.discount_percentage}
            discound_value={coupon.discount_value}
            limit={coupon.limit}
            from_date={coupon.valid_from}
            to_date={coupon.valid_to}
            id={coupon.id}
            deleteCoupon = {()=> deleteCoupon(coupon.id)}
         icon={
           <PartyMode 
           sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
           />
          }
          />
          
          ))}
          </div>
          </>
      )}
    </Box>
  );
};

export default ViewCoupons;
